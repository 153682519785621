<template>
  <div>
    <el-card class="box-card">
          <el-form :model="form" ref="form" :rules="rules">
            <el-form-item label="彩页封面" label-width="100px" prop="imageUrl">
              <el-upload
                class="avatar-uploader"
                :action="AppUploadImageUrl"
                :show-file-list="false"
                :on-success="imageSuccess"
                :headers="AuthHeaders()"
              >
                <img v-if="form.imageUrl" :src="form.imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <el-button @click="addAppWx">保存</el-button>
            </el-form-item>
      </el-form>
      <el-row>
  <el-col :span="4" v-for="(item, index) in appWxList" :key="index" :offset="index > 0 ? 2 : 0">
    <el-card :body-style="{ padding: '0px' }">
      <img :src="item.picOnline" class="image">
      <div style="padding: 14px;">
        <div>
          <el-button @click="remove(item.id)">删除</el-button>
        </div>
      </div>
    </el-card>
  </el-col>
</el-row>
    </el-card>
  </div>
</template>
<script>
import { mixins } from '@/util/mixins.js'
export default {
  mixins: [mixins],
  data(){
    return{
      dialogFormVisible:false,
      form:{
        imageUrl:'',
      },
      images:'',
      appWxList:[],
      rules: {
        imageUrl: [
          { message: '请上传彩页封面', trigger: 'change', required: true },
        ]
      },
    }
  },
  created(){
    this.getAppWx()
  },
  methods:{
    imageSuccess(file,e){
      this.$set(this.form, 'imageUrl', file.onLine)
      this.images = file.onLine
    },
    addAppWx(){
      this.$request({
        url:'/offerSystem/app/slideshow/insertSlideshow',
        method:'post',
        data:{
          onLine:this.images
        }
      }).then(res =>{
        this.form.imageUrl = ''
        this.getAppWx()
      })
    },
    getAppWx(){
      this.$request({
        url:'/offerSystem/app/slideshow/getSlideshow',
        method:'get',
      }).then(res =>{
        this.appWxList = res.result
      })
    },
    remove(id){
      this.$request({
        url:`offerSystem/app/slideshow/deleteSlideshow?id=${id}`,
        method:'put'
      }).then(res =>{
        this.getAppWx()
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .time {
    font-size: 13px;
    color: #999;
  }
  
  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    width: 100%;
    display: block;
  }

  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }
</style>